<template>
  <div>
    <b-card>
      <b-row>
        <b-col 
          cols="6"
          class="px-2 my-auto"
        >
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ roleData.name }}
              </h4>
              <div class="card-text">
                {{ roleData.description }}
              </div>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'roles-edit', params: { id: roleData.id } }"
                variant="primary"
              >
                Edit Role
              </b-button>
            </div>
          </div>
        </b-col>
        <b-col cols="6">
          <table class="mt-2 mt-xl-0 w-100">
            <!-- Created At -->
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="ClockIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Created At</span>
              </th>
              <td>
                {{ $formatDatetime(roleData.created_at) }}
              </td>
            </tr>

            <!-- Created by -->
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Created By</span>
              </th>
              <td>
                {{ roleData.created_by }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <permissions-card
        v-if="roleData.permissions"
        :disabled="true"
        :user-permissions="roleData.permissions"
      />
    </b-card>

    <!-- <b-card>
      <h1> table of users with this role </h1>
    </b-card> -->
  </div>
</template>

<script>
import PermissionsCard from '@/views/user-management-system/components/PermissionsCard.vue'

export default {
  components: {
    PermissionsCard,
  },
  data() {
    return {
      roleData: {},
      showPermissionsCard: false,
    }
  },
  watch: {
    'roleData.permissions': function (newVal, oldVald) {
      this.showPermissionsCard = true
    }
  },
  created() {
    this.get_role()
    this.get_users_with_this_role()
  },
  methods: {
    get_role() {
      const { id } = this.$route.params
 
      this.$http
        .get(`/api/roles/${id}`)
        .then(response => {
          this.roleData = response.data

          const { permissions } = this.roleData
          this.$set(this.roleData, 'permissions', {})
          this.$set(this.roleData, 'permissions', permissions)
        })
        .catch(error => {
          this.$handleErrorResponse(error)
          const { response } = error

          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Operation Failed',
                icon: 'CheckCircleIcon',
                text: response.data.message,
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )

          this.isLoading = false
        })
    },
    get_users_with_this_role() {
      const { id } = this.$route.params
 
      this.$http
        // .get(`/api/users?filter_by_role=${id}`)
        .get(`/api/roles/${id}/users`)
        .then(response => {
          this.usersData = response.data.output
        })
        .catch(error => {
          const { response } = error

          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Operation Failed',
                icon: 'CheckCircleIcon',
                text: response.data.message,
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )

          this.isLoading = false
        })
    },
  },
}
</script>

<style>

</style>
